@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.TypefaceHero {
  --typeface-hero-bg: var(--page-theme-background, black);
  --typeface-hero-color: var(--page-theme-primary, white);
  --header-color: var(--typeface-hero-color);
  --color-primary: var(--typeface-hero-color);

  display: flex;
  flex-direction: column;

  min-height: 667px;
  overflow: hidden;

  padding: var(--header-height) 0 calc(2 * var(--spacing-2xl)) 0;

  text-align: center;
  background-color: var(--typeface-hero-bg);

  @media (min-width: $tablet-min-breakpoint) {
    padding: var(--header-height) 0 var(--spacing-2xl) 0;
  }

  @media (min-width: $desktop-min-breakpoint) {
    min-height: 810px;
  }
}

.TypefaceHero__text {
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  max-width: var(--page-width);
  margin: 0 auto;
  padding: var(--spacing-2xl) var(--grid-margin);

  @media (min-width: $desktop-min-breakpoint) {
    padding: calc(2 * var(--spacing-2xl)) var(--grid-margin);
  }

  h1 {
    font-family: var(--typeface-hero-font);
    font-size: var(--typeface-hero-clamp);
    word-break: break-all;

    div {
      font-family: var(--typeface-sub-hero-font);
    }
  }
}

.title {
  overflow-x: auto;
  overflow-y: hidden;

  @media (max-width: $mobile-max-breakpoint) {
    overflow-x: scroll;
  }
}

.TypefaceHero__features {
  flex: 0 0 auto;

  display: flex;
  justify-content: center;
}

.inner {
  display: none;

  @media (min-width: $tablet-min-breakpoint) and (max-width: $desktop-max-breakpoint) {
    margin: 0 var(--spacing-xl);
  }

  @media (min-width: $desktop-max-breakpoint) {
    display: flex;
    flex: 0 1 auto;
    padding: var(--spacing-s) var(--spacing-base);
  }
}

.inner {
  justify-content: flex-start;
  border: 1px solid var(--typeface-hero-color);
  border-radius: 56px;

  & > div {
    flex: 0 0 auto;
    padding: 0 var(--spacing-base);

    @media (min-width: $tablet-min-breakpoint) {
      padding: 0 var(--spacing-l);
    }

    @media (min-width: $desktop-min-breakpoint) {
      padding: 0 40px;
    }

    &:first-child {
      padding-left: var(--spacing-l);
    }

    &:last-child {
      padding-right: var(--spacing-l);
    }

    &:not(:last-of-type) {
      border-right: 1px solid var(--typeface-hero-color);
    }
  }

  p,
  h4 {
    color: var(--typeface-hero-color);
  }

  p {
    font-size: rem-calc(14);
    line-height: 1.28;
    margin-bottom: 5px;
  }

  h4 {
    font-weight: 400;
    font-size: rem-calc(36);
  }
}

.title,
.inner,
.innerMobileWrapper {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
